import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import Layout from "../components/layout";
import SEO from "../components/seo";

export const query = graphql`
  query($slug: String) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      mainImage {
        asset {
          fixed(width: 300) {
            ...GatsbySanityImageFixed
          }
        }
        alt
      }
      batch {
        batch {
          number
          releaseDate
          file {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;

const Product = ({ data }) => {
  const product = data.sanityProduct;
  return (
    <Layout>
      <SEO slug={product.slug.current} />
      <section>
        <Link to="/">⟵ Go to the COA homepage.</Link>
        <br />
        <h1
          css={css`
            font-size: 2.5em;
          `}
        >
          {product.title}
        </h1>
      </section>
      <section>
        <Img
          fixed={product.mainImage.asset.fixed}
          alt={product.mainImage.alt}
        />
        {product.batch.map(({ batch }) => (
          <div key={batch.number}>
            <a
              href={batch.file.asset.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {batch.number} | {batch.releaseDate}
            </a>
          </div>
        ))}
      </section>
    </Layout>
  );
};
export default Product;
